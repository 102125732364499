import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AlertController,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonTextarea,
} from '@ionic/angular/standalone';
import { ICigar, IState } from '@models';
import { Store } from '@ngrx/store';
import { ModalService } from '@services/modal.service';
import { addIcons } from 'ionicons';
import { trashOutline } from 'ionicons/icons';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonTextarea,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start" *ngIf="cigar.MyNote">
          <ion-button
            fill="clear"
            shape="round"
            color="danger"
            (click)="remove()"
            [strong]="true"
            [autofocus]="false"
          >
            <ion-icon name="trash-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Personal Note</ion-title>
        <ion-buttons slot="end">
          <ion-button
            fill="outline"
            shape="round"
            color="primary"
            (click)="submit()"
            [strong]="true"
            [autofocus]="false"
            [disabled]="!formGroup.dirty"
          >
            Submit
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <form [formGroup]="formGroup" (ngSubmit)="submit()" novalidate>
        <ion-list lines="full" class="ion-margin-bottom">
          <ion-item>
            <ion-textarea
              tabindex="-1"
              labelPlacement="stacked"
              label="Write your note..."
              autocorrect="on"
              autocomplete="off"
              [autofocus]="false"
              [counter]="true"
              [autoGrow]="true"
              rows="8"
              formControlName="Note"
              [errorText]="
                formGroup.touched && formGroup.get('Note').hasError('required')
                  ? 'required'
                  : ''
              "
            >
            </ion-textarea>
          </ion-item>
        </ion-list>
        <button type="submit" [hidden]="true">Submit</button>
      </form>
    </ion-content>
  `,
})
export class MyNoteModal implements OnInit {
  @Input() cigar: ICigar;

  formGroup = this.fb.group({
    Note: ['', [Validators.required]],
  });

  constructor(
    protected store: Store<IState>,
    private modalService: ModalService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private alertCtrl: AlertController
  ) {
    addIcons({ trashOutline });
  }

  ngOnInit() {
    this.formGroup.patchValue({
      Note: this.cigar.MyNote?.Note || '',
    });
    this.cdr.markForCheck();
  }

  submit() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    this.modalService.dismiss(this.formGroup.value, 'submit');
  }

  async remove() {
    const alert = await this.alertCtrl.create({
      header: 'You are about to delete a note',
      message: 'Are you sure?',
      buttons: [{ text: 'Cancel' }, { text: 'Yes', role: 'remove' }],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'remove') {
      this.modalService.dismiss(null, 'remove');
    }
  }
}
